import React from "react";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const AzSplice = () => {
  return (
    <Layout>
      <Head title="AZ aka Splice Poet LKL" />
      <h3>AZ aka Splice Poet LKL</h3>
      <h4>
        <FaGlobe />
        Texas
      </h4>
      {/* <p>
        <OutboundLink href="https://www.oceanvuong.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Ocean_Vuong">
          Wikipedia
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/ocean_vuong/">
          Instagram
        </OutboundLink>
      </p> */}

      <div className="azPoemContainer">
        <p>The Dance called self projection</p>

        <p>With a steady green flow waxing in our bells</p>

        <p>We find harmony</p>

        <p>Your waist rotates, sensually by the step</p>

        <p>
          My neck never roams horizontally but gradually across to your inducing
          horizon Twirl, swirl, curl
        </p>

        <p>Unwrap the embrace</p>

        <p>Hands should touch</p>

        <p>Faster</p>

        <p>We have a sanging melody</p>

        <p>We portray it in our carefree rhythm</p>

        <p>Hands should touch; but we move around</p>

        <p>Stay around or dance in place</p>

        <p>Sunny lit faces peel; One hand wet, another hand greasy</p>

        <p>Twirl swirl curl</p>

        <p>The trees dance with us, we are their wind</p>

        <p>The trees dance we are their sirens, it echoes in the wind</p>

        <p>Unwrap the embrace, as we fall between together</p>

        <p>Old glam glibs on; we move on</p>

        <p>If we will stop we will turn to wood. Nowhere to escape too</p>

        <p>The music is the line in our swaggered limbs</p>

        <p>Faster we twirl, we swirl, we curl</p>

        <p>
          We are dancing with gnarled smiles, tossed hair brushes my hand, and
          stare in the momentary
        </p>

        <p>dance</p>

        <p>Hands in open air: yet not touching</p>
      </div>
      {/* <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1671922747/artnow/az/WIN_20220915_21_54_19_Pro.jpg"
        alt="AZ aka Splice Poet LKL"
      /> */}
      <div className="azPoemContainer">
        <p>The Teary Shoe</p>

        <p>When I Z to death Jordan, Always believing to trust</p>

        <p>I jammed my hot rod with mature hallucination</p>

        <p>Asphyxiating in the jiwi my piwi came to dream of our roll</p>

        <p>
          She is just hot encinder and please accelerate my cheetah heart with
          mortal weakness Sugar cannot define a better high
        </p>

        <p>We could hold a kiss in the smokey street</p>

        <p>Zip up my jacket on you and I will phrase my touch</p>

        <p>
          Dance by the pool, I cannot afford the leather but I got a collar tied
          to jniss
        </p>

        <p>The meds nurse me to you I grin at your sparkling eyes</p>

        <p>
          I fox your poke a hot dot black navel with a less than circle feel
        </p>

        <p>Riding a toned loned awi</p>

        <p>Beezy we can be by grace and rhythm</p>

        <p>I raced last to ace a step closer only for a kiss</p>

        <p>You might be sore at me like a rough spiky thorn but</p>

        <p>
          I cannot stand alone so speak of the devil unveil my eyes with your
          highness Your fabulous hair joints my eyes
        </p>

        <p>Aching for your chin and sweet neck in the dark</p>

        <p>A black jacket a heroine brain</p>

        <p>The only injection I need is the truth of</p>

        <p>Everything for you and a sweet thing for me</p>

        <p>Sticky icky were near the Fertile wertile</p>
      </div>
      <div className="azPoemContainer">
        <p>A Other Dream</p>

        <p>I am not atleast perfect</p>

        <p>I crave your touch the pick and a pinch</p>

        <p>Perhaps something went right dreams lie open</p>

        <p>I want to know your name</p>

        <p>So I call you Anjelica, I cherish a dragged sight</p>

        <p>Have I only said: you are a good kisser</p>

        <p>Shorten woo’s heart; pump your eyelids repeatedly</p>

        <p>I decipher your language</p>

        <p>You would be my experience</p>

        <p>I do not hold my breath</p>

        <p>Caringly tell me</p>

        <p>We could pant lust’s reached tongue</p>

        <p>Grip my finger and I will braze your thigh</p>

        <p>You sour head; my smoked mind</p>

        <p>Ahead of this section, I sedict</p>

        <p>Can I lech, wax my tongue</p>

        <p>
          My nose could tuck with your chin as I honey under lick your throat I
          cannot close another eye. Lids are heavy
        </p>

        <p>A fire fierce will be missed</p>

        <p>A secret desire right in your face</p>

        <p>Every morning a thought of you</p>

        <p>Only relevant to now know</p>
      </div>
    </Layout>
  );
};

export default AzSplice;
